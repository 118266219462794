import Image from 'next/image';
import { FC } from 'react';

import { MICROCOPY } from 'src/data/microcopy/microcopyDictionary';
import carmaGenericImageLoader from 'src/general/helpers/carmaGenericImageLoader';
import { getMicrocopyBulk } from 'src/stores/microcopyStore';

import styles from './HighlightCarmaSponsorships.module.scss';
import classNames from 'classnames';

export interface HighlightCarmaSponsorshipsProps {}

export const HighlightCarmaSponsorships: FC<HighlightCarmaSponsorshipsProps> = () => {
  const { header, description } = getMicrocopyBulk({
    header: [MICROCOPY.HYGIENE_HOMEPAGE_HIGHLIGHT_CARMA_SPONSORSHIPS_HEADER],
    description: [MICROCOPY.HYGIENE_HOMEPAGE_HIGHLIGHT_CARMA_SPONSORSHIPS_DESCRIPTION],
  });
  return (
    <section className={styles.root}>
      <div className={styles.container}>
        <h2 className={classNames('c-fw-bold', 'c-fs-h4', styles.header)}>{header}</h2>
        <div className={styles.imageContainer}>
          <Image
            src="/images/components/HighlightCarmaSponsorships/Sydney FC.svg"
            width={111}
            height={111}
            alt="Sydney FC"
            loader={carmaGenericImageLoader}
          />
          <Image
            src="/images/components/HighlightCarmaSponsorships/Sydney Kings.svg"
            width={111}
            height={111}
            alt="Sydney Kings"
            loader={carmaGenericImageLoader}
          />
          <Image
            src="/images/components/HighlightCarmaSponsorships/Sydney Flames.svg"
            width={111}
            height={111}
            alt="Sydney Flames"
            loader={carmaGenericImageLoader}
          />
        </div>
        <p className={classNames('c-fw-normal', 'c-fs-h6', styles.description)}>{description}</p>
      </div>
    </section>
  );
};
